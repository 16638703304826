import styles from "./AllProtocolsPage.module.css";
import { getReportPreviewsFilteredURL, getReportPreviewsURL } from "../../api";
import { useEffect, useState } from "react";
import RatingBar from "../../components/RatingBar";
import { Link } from "react-router-dom";
import SearchBar from "../../components/Reusable/SearchBar";
import { sendRequest } from "../../utils/apiRequest";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import FilterSelect from "../../components/Reusable/FilterSelect";

interface IAllProtocolsPage {
  setCurrentProtocolID: (addProtocolActive: number) => void;
}
type filterOption = {
  lable: string,
  key: string,
}
const AllProtocolsPage: React.FC<IAllProtocolsPage> = ({
  setCurrentProtocolID,
}) => {
  const [allProtocols, setAllProtocols] = useState<any[] | null>(null);
  const [protocolSearch, setProtocolSearch] = useState("");
  const filterOptions : filterOption[] = [{lable: "Code Lines",key:"linesOfCode"},{lable: "Bug Bounty",key:"bugBounty"}];
  const [filters,setFilters] = useState<filterOption[]>([]);
  
  const handleProtocolSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProtocolSearch(e.target.value);
  };

  const handleFilterChange =
  (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(filters.find(filter => filter.lable === event.target.value)){
      return;
    }
    filters[index] = filterOptions.find(filter => filter.lable === event.target.value) ?? {lable: "Audit Amount",key:"auditAmount"};
    setFilters([ ...filters ]);
  };

  const addFilter = () => {
    if(filters.length === filterOptions.length){
      return;
    }
    setFilters([...filters,filterOptions[filters.length]]);
  };

  const sendSearchRequest = async () => {
    const keys = filters.map(filter => filter.key);
    await sendRequest(
      getReportPreviewsFilteredURL,
      "Error getting Protocols",
      undefined,
      keys,
      setAllProtocols,
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      await sendRequest(
        getReportPreviewsURL,
        "Error getting Protocols",
        undefined,
        undefined,
        setAllProtocols,
      );
    };
    if(!allProtocols){
      fetchData();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.reportContainer}>
        <div className={styles.header}>
          <h3>Protocols</h3>
        </div>
        <div className={styles.Search}>
          <SearchBar
            value={protocolSearch}
            onChange={handleProtocolSearchChange}
            placeholder="Search for protocol"
          />
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            onClick={sendSearchRequest}
          >
            Search
          </button>
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
          >
            Clear
          </button>
        </div>
        <div className={styles.filerRow}>
        {
          filters.map((filter,index) => (
            <FilterSelect options={filterOptions} value={filter.lable} curValue="lable" onChange={handleFilterChange(index)} display="lable"/>
          ))
        }
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
            onClick={() => addFilter()}
          >
            + Add filter
          </button>
        </div>
        <ul className={styles.CompanieList}>
          <li className={styles.row}>
            <p>Protocol</p>
            <p>Protocol Audit Amount</p>
            {filters.map(filter => (
                    <>
                    {
                      filter !== undefined ?
                      allProtocols ?
                      allProtocols[0][filter.key] !== undefined  ? <p>{filter.lable}</p> : <></> :
                      <></> : <></>
                    }
                    </>
            ))}
            <p className={styles.protocolRating}>Protocol Rating</p>
          </li>
          {allProtocols ? allProtocols
            .filter((protocol) =>
              protocol.name
                .toLowerCase()
                .startsWith(protocolSearch.toLowerCase()),
            )
            .map((protocol) => (
              <Link
                onClick={() => setCurrentProtocolID(protocol.rid)}
                to={`/protocol/${protocol.name}`}
                key={protocol.name}
                className={styles.row}
              >
                <div className={styles.rowNameContainer}>
                  <img alt="" src={protocol.logoURL} className={styles.image} />
                  <p>{protocol.name}</p>
                </div>
                <p>{protocol.auditAmount}</p>
                {filters.map(filter => (
                    <>
                    {
                      filter !== undefined ?
                      protocol[filter.key] !== undefined  ? <p>{protocol[filter.key]}</p> : <></> :
                      <></>
                    }
                    </>
                  ))}
                <div className={styles.rating}>
                  <RatingBar
                    rating={protocol.score}
                    color1={"#FF6384"}
                    color2={"#FFCE56"}
                    color3={"#4BC0C0"}
                  />
                </div>
              </Link>
            )): <></>}
        </ul>
      </div>
    </div>
  );
};

export default AllProtocolsPage;
