import { useEffect, useState } from "react";
import styles from "./PublishPage.module.css";
import { userData } from "../../utils/severRequestTypes";

import { getReportPreviewsPublishedByUserURL, getReportPreviewsUnderReviewAdURL, getReportPreviewsUnderReviewURL, publishReportURL } from "../../api";

import { protocolPreview } from "../../utils/reportDataTypes";
import { sendRequest } from "../../utils/apiRequest";
import { Link } from "react-router-dom";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import SearchBar from "../../components/Reusable/SearchBar";
import SideMeunPublishMainPage from "./SideMenu";
import { MdGppGood } from "react-icons/md";

interface IPublishPage {
  user: userData | null;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const PublishPage: React.FC<IPublishPage> = ({
  user,
  setCurrentProtocolID,
}) => {
  const [allprotocols, setAllprotocols] = useState<protocolPreview[] | null>(null);
  const [allPublishedProtocols, setAllPublishedProtocols] = useState<protocolPreview[] | null>(null);
  const [confirmPublish,setConfirmPublish] = useState<number>(0);
  const [confirmDelete,setConfirmDelete] = useState<number>(0);

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      await sendRequest(
        user?.isAdmin
          ? getReportPreviewsUnderReviewAdURL
          : getReportPreviewsUnderReviewURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllprotocols,
      );
      await sendRequest(
        getReportPreviewsPublishedByUserURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllPublishedProtocols,
      );
    };
    fetchProtocolPreviews();
  }, [user?.isAdmin]);

  const publishReport = async (id: number) => {
    if(id !== confirmPublish){
      setConfirmPublish(id);
      return;
    }
    await sendRequest(
      publishReportURL,
      "Something went wrong while publish!",
      undefined,
      id,
      undefined,
    );
  };
  const deleteReport = async (id: number) => {
    if(id !== confirmPublish){
      setConfirmDelete(id);
      return;
    }
    //Send request
  };

  if (user !== null) {
    return (
      <div className={styles.container}>
        <SideMeunPublishMainPage isAdmin={user.isAdmin}/>
        <div className={styles.containerInner} style={{ width: "100%" }}>
          <div className={styles.header}>
            <b>Edit Protocol Entries</b>
            <div className={styles.Search}>
              <SearchBar value="" onChange={() => {}} placeholder="Search entries..."/>
              <button className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}>
              Search
              </button>
            </div>
          </div>
          <ul className={styles.ReportList}>
            {allprotocols !== null && Array.isArray(allprotocols) ? allprotocols.map((protocol) => {
              return (
                <li className={styles.row} key={protocol.rid}>
                  <h4 className={styles.IDtext}>{protocol.rid}</h4>
                  <img
                    className={styles.logo}
                    src={protocol.logoURL}
                    alt=""
                  />
                  <p className={styles.text}>{protocol.name}</p>
                  <p
                    className={styles.text}
                    style={{ color: "rgba(255, 159, 64, 1)" }}
                  >
                    Under Review
                  </p>
                  {user.isAdmin ? (
                    <button 
                    className={`${inputControlStyles.button} ${confirmPublish !== protocol.rid ? inputControlStyles["button--tertiary"] : inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
                    onClick={() => publishReport(protocol.rid)}
                    >
                      {confirmPublish === protocol.rid ? "Confirm" : "Publish"}
                    </button>
                  ) : (
                    ""
                  )}
                  <Link
                    className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
                    to={`/protocol/${protocol.name}?id=${protocol.rid}&underReview=1`}
                    onClick={() => setCurrentProtocolID(protocol.rid)}
                  >
                    View
                  </Link>
                  <Link
                    className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
                    to={`/publish/protocol?id=${protocol.rid}`}
                  >
                    {" "}
                    Edit{" "}
                  </Link>
                  {user.isAdmin ? (
                    <button 
                    className={`${inputControlStyles.button} ${inputControlStyles["button--delete"]} ${inputControlStyles["button--small"]}`}
                    onClick={() => deleteReport(protocol.rid)}
                    >
                      {confirmDelete === protocol.rid ? "Confirm" : "X"}
                    </button>
                  ) : (
                    ""
                  )}
                </li>
              );
            }): (<></>)}
            {allPublishedProtocols !== null && Array.isArray(allPublishedProtocols) ? allPublishedProtocols.map((protocol) => {
              return (
                <li className={styles.row} key={protocol.rid}>
                  <h4 className={styles.IDtext}>{protocol.rid}</h4>
                  <img
                    className={styles.logo}
                    src={protocol.logoURL}
                    alt=""
                  />
                  <p className={styles.text}>{protocol.name}</p>
                  <p
                    className={styles.text}
                    style={{ color: "#4bc0c0" }}
                  >
                    Published
                  </p>
                  {user.isAdmin ? (
                    <button 
                    className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
                    onClick={() => publishReport(protocol.rid)}
                    >
                      Publish
                    </button>
                  ) : (
                    ""
                  )}
                  <Link
                    className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
                    to={`/protocol/${protocol.name}?id=${protocol.rid}&underReview=1`}
                    onClick={() => setCurrentProtocolID(protocol.rid)}
                  >
                    View
                  </Link>
                  <Link
                    className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
                    to={`/publish/protocol?id=${protocol.rid}`}
                  >
                    {" "}
                    Edit{" "}
                  </Link>
                </li>
              );
            }): (<></>)}
          </ul>
          {
            (allPublishedProtocols === null || !Array.isArray(allPublishedProtocols)) && 
            (allprotocols !== null || Array.isArray(allprotocols)) ? 
            <div className={styles.alertContainer}>
                <div className={styles.icon}>
                  <MdGppGood size={35} />
                </div>
                <p>Submit Reports to get 3 month free SCAS Pro. Read more on how to correctly submit reports on our docs</p>
              </div> : 
            <></>
          }
        </div>
      </div>
    );
  } else {
    return <>Error Please Login</>;
  }
};

export default PublishPage;
