import Report from "../../components/Report";
import styles from "./PublicationsPage.module.css";
import { getReportPreviewsURL } from "../../api";
import { useEffect, useState } from "react";
import { protocolPreview } from "../../utils/reportDataTypes";
import { Link } from "react-router-dom";
import { CiCircleMore } from "react-icons/ci";
import SearchBar from "../../components/Reusable/SearchBar";
import { darkTheme } from "../../utils/theme";
import FeaturedAuditCompany from "../../components/FeaturedAuditCompany";
import { sendRequest } from "../../utils/apiRequest";

interface IPublicationsPage {
  currentTheme?: typeof darkTheme;
  setCurrentProtocolID: (addProtocolActive: number) => void;
  setProtocolPreviews: (protocols: protocolPreview[]) => void;
  allprotocolsParam: protocolPreview[] | null;
}

const PublicationsPage: React.FC<IPublicationsPage> = ({
  setCurrentProtocolID,
  setProtocolPreviews,
  allprotocolsParam,
}) => {
  const [allprotocols, setAllprotocols] = useState<protocolPreview[] | null>(
    allprotocolsParam,
  );
  const [protocolSearch, setProtocolSearch] = useState("");
  const [reportsPerRow, setReportsPerRow] = useState(0);

  const handleProtocolSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProtocolSearch(e.target.value);
  };

  useEffect(() => {
    const setProtocolPrev = (protocols: protocolPreview[]) => {
      setAllprotocols([...protocols]);
      setProtocolPreviews([...protocols]);
    };
    const fetchProtocolPreviews = async () => {
      await sendRequest(
        getReportPreviewsURL,
        "Error getting Preview",
        undefined,
        undefined,
        setProtocolPrev,
      );
    };
    fetchProtocolPreviews();
  }, [setProtocolPreviews]);

  useEffect(() => {
    const reportsContainer = document.getElementById("reports");
    const report = document.getElementById("report");
    const containerWidth = reportsContainer ? reportsContainer.offsetWidth : 0;
    const singleReportWidth = report ? report.offsetWidth : 0;
    if (containerWidth > 0 && singleReportWidth > 0) {
      const reportsPerRow = Math.floor(containerWidth / singleReportWidth);
      setReportsPerRow(reportsPerRow);
    }
  }, []);

  return (
    <div className={styles.publications}>
      <div className={styles.container}>
        <div className={styles.reportContainer}>
          <h3>
            Top Security Scores{" "}
            <Link to={`/all-protocols`} className={styles.moreLink}>
              view all <CiCircleMore />
            </Link>
          </h3>
          <SearchBar
            value={protocolSearch}
            onChange={handleProtocolSearchChange}
            placeholder="Search for protocol"
          />
          <div id="reports" className={styles.reports}>
            {allprotocols ? allprotocols
              .slice(0, reportsPerRow === 0 ? 1 : reportsPerRow)
              .sort((a, b) => b.score - a.score)
              .map((report) => (
                <Report
                  ID={report.rid}
                  setCurrentProtocolID={setCurrentProtocolID}
                  key={report.rid}
                  title={report.name}
                  image={report.logoURL}
                  score={report.score}
                  audits={report.auditAmount}
                />
              )) : <></>}
          </div>
          <h3 style={{ marginTop: "2rem" }}>
            Recently Added{" "}
            <Link to={`/all-protocols`} className={styles.moreLink}>
              view all <CiCircleMore />
            </Link>
          </h3>
          <div
            id="reports"
            style={{ marginTop: "15px" }}
            className={styles.reports}
          >
            {allprotocols ? allprotocols
              .slice(0, reportsPerRow === 0 ? 1 : reportsPerRow)
              .map((report) => (
                <Report
                  ID={report.rid}
                  setCurrentProtocolID={setCurrentProtocolID}
                  key={report.rid}
                  title={report.name}
                  image={report.logoURL}
                  score={report.score}
                  audits={report.auditAmount}
                />
              )): <></>}
          </div>
        </div>
        <FeaturedAuditCompany />
      </div>
    </div>
  );
};

export default PublicationsPage;
