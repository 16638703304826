import styles from "./MetricContainer.module.css";

interface IMetricContainer{
    title: JSX.Element;
    content: JSX.Element;
}

const MetricContainer: React.FC<IMetricContainer> = ({title,content}) => {

  return (
    <div className={styles["metric-container"]}>
        <p>{title}</p>
        <h2>{content}</h2>
    </div>
  );
};

export default MetricContainer;
