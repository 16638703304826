import RatingBar from "../RatingBar";
import styles from "./Report.module.css";
import { Link } from "react-router-dom";
import { setColorFromSecScore } from "../../utils/colorFromSecScore";

interface IReport {
  title: String;
  image: string;
  score: number;
  audits: number;
  ID: number;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const Report: React.FC<IReport> = ({
  title,
  image,
  score,
  audits,
  ID,
  setCurrentProtocolID,
}) => {
  const pageLink = "./protocol/" + title + "?id=" + ID;
  return (
    <div id="report" className={styles.report}>
      <Link to={pageLink} onClick={() => setCurrentProtocolID(ID)}>
        <div className={styles.titleContainer}>
          <img alt="" className={styles.logo} src={image} />
          <div className={styles.titleTextConatainer}>
            <span className={styles.title}>{title}</span>
            <div className={styles.audits}>
              <p>Audits: </p>
              <p className={styles.auditsNumber}>{audits}</p>
            </div>
          </div>
        </div>
        <div className={styles.rating}>
          <RatingBar
            rating={score}
            color1={"#FF6384"}
            color2={"#FFCE56"}
            color3={"#4BC0C0"}
          />
          <p
            style={{
              color: setColorFromSecScore(
                score,
                "#FF6384",
                "#FFCE56",
                "#4BC0C0",
              ),
            }}
          >
            {score}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default Report;
