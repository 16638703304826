import styles from "./SecInfra.module.css";
import {
  secInfra,
  auditCompanies,
  protocolData,
} from "../../../utils/reportDataTypes";
import { isNumber } from "../../../utils/adminUtils";
import { useState, useEffect } from "react";
import FormInput from "../../Reusable/FormInput";
import BasicList from "../../Reusable/BasicList";
import axios from "axios";
import { getAllAuditCompaniesURL, writePastAuditDataURL } from "../../../api";
import {
  auditDataSubmittRequest,
  auditReportLink,
} from "../../../utils/severRequestTypes";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";

interface ISecInfra {
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

export const SecInfra: React.FC<ISecInfra> = ({
  protocolData,
  setProgress,
}) => {
  const [allAuditCompanies, setAllAuditCompanies] = useState<auditCompanies[]>(
    [],
  );
  console.log(allAuditCompanies);
  const [requestSend, setReuqestSend] = useState<boolean>(false);
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0]);
  const initialArray: auditReportLink[] = protocolData
    ? protocolData.pastAudits.audits.map(({ link, company }) => ({
        auditReportLink: link,
        auditedBy: company,
        vulnSectionStart: protocolData?.secInfra.vulnSectionStart ? Number(protocolData.secInfra.vulnSectionStart) : undefined,
        vulnSectionEnd: protocolData?.secInfra.vulnSectionEnd ? Number(protocolData.secInfra.vulnSectionEnd) : undefined,
      }))
    : [];
  const [auditReportLinksObj, setAuditReportLinksObj] =
    useState<auditReportLink[]>(initialArray);

  console.log(auditReportLinksObj);

  const [secData, setSecData] = useState<secInfra>({
    bugBounty: protocolData
      ? protocolData.secInfra.bugBounty === null
        ? ""
        : protocolData.secInfra.bugBounty
      : "",
    bugBountyAmount: protocolData ? protocolData.secInfra.bugBountyAmount : 0,
    paidWhiteHatBefore: protocolData
      ? protocolData.secInfra.paidWhiteHatBefore
      : "",
    auditCompanyIDs: [],
    recentSecIndicents: [],
    vulnSectionStart: protocolData?.secInfra.vulnSectionStart || "",
    vulnSectionEnd: protocolData?.secInfra.vulnSectionEnd || "",
  });

  useEffect(() => {
    setSecData({
      bugBounty: protocolData
        ? protocolData.secInfra.bugBounty === null
          ? ""
          : protocolData.secInfra.bugBounty
        : "",
      bugBountyAmount: protocolData ? protocolData.secInfra.bugBountyAmount : 0,
      paidWhiteHatBefore: protocolData
        ? protocolData.secInfra.paidWhiteHatBefore
        : "",
      auditCompanyIDs: [],
      recentSecIndicents: [],
      vulnSectionStart: protocolData?.secInfra.vulnSectionStart || "",
      vulnSectionEnd: protocolData?.secInfra.vulnSectionEnd || "",
    });
    setAuditReportLinksObj(
      protocolData
        ? protocolData.pastAudits.audits.map(({ link, company }) => ({
            auditReportLink: link,
            auditedBy: company,
            vulnSectionStart: protocolData?.secInfra.vulnSectionStart ? Number(protocolData.secInfra.vulnSectionStart) : undefined,
            vulnSectionEnd: protocolData?.secInfra.vulnSectionEnd ? Number(protocolData.secInfra.vulnSectionEnd) : undefined,
          }))
        : [],
    );
  }, [protocolData]);

  useEffect(() => {
    const sendGetProductsRequest = async () => {
      try {
        const { data } = await axios.post(getAllAuditCompaniesURL, "{}", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const products: auditCompanies[] = data;
        setAllAuditCompanies(
          products.sort((a, b) => a.name.localeCompare(b.name)),
        );
      } catch (e) {
        console.log(e);
      }
    };

    sendGetProductsRequest();
  }, []);

  const sendWritePastAuditDataRequest = async () => {
    setReuqestSend(true);
    const request: auditDataSubmittRequest = {
      protocolReportID: protocolData !== null ? protocolData.rid : 0,
      pastAuditLinks: auditReportLinksObj,
      secData: secData,
    };
    sendRequest(
      writePastAuditDataURL,
      "Something went wrong while write!",
      "Added Data!",
      request,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 4]);
    }
  };

  if (protocolData && protocolData.secInfra !== undefined) {
    if (protocolData.secInfra.bugBounty !== null) {
      if (protocolData.secInfra.bugBounty.length > 0) {
        setProgressHelper(1);
      }
    }
    if (protocolData.secInfra.bugBountyAmount !== null) {
      if (protocolData.secInfra.bugBountyAmount > 0) {
        setProgressHelper(2);
      }
    }
    if (protocolData.secInfra.paidWhiteHatBefore !== null) {
      if (protocolData.secInfra.paidWhiteHatBefore.length > 0) {
        setProgressHelper(3);
      }
    }
    if (protocolData.pastAudits !== null) {
      if (protocolData.pastAudits.audits !== null) {
        if (protocolData.pastAudits.audits.length > 0) {
          setProgressHelper(0);
        }
      }
    }
  }

  const handleAddAuditLink = () => {
    secData.auditCompanyIDs.push(0);
    setSecData({ ...secData });

    const arr2: auditReportLink[] = auditReportLinksObj.concat([
      { auditReportLink: "", auditedBy: 0, vulnSectionStart: undefined, vulnSectionEnd: undefined },
    ]);
    setAuditReportLinksObj([...arr2]);
    setProgressHelper(0);
  };

  const handleAddAuditLinkChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      auditReportLinksObj[index].auditReportLink = e.target.value;
      setAuditReportLinksObj([...auditReportLinksObj]);
    };

  const handleRemoveAuditLink = (index: number) => {
    const arr2: auditReportLink[] = auditReportLinksObj;
    arr2.splice(index, 1);
    setAuditReportLinksObj([...arr2]);

    delete secData.auditCompanyIDs[index];
    setSecData({ ...secData });
  };

  const handleBugBountyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    secData.bugBounty = e.target.value;
    setSecData({ ...secData });
    setProgressHelper(1);
  };

  const handleBugBountyAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let num = Number(e.target.value);
    if (isNumber(num)) {
      secData.bugBountyAmount = num;
      setSecData({ ...secData });
      setProgressHelper(2);
    }
  };

  const handlePaidWhiteHatBefore = (e: React.ChangeEvent<HTMLInputElement>) => {
    secData.paidWhiteHatBefore = e.target.value;
    setSecData({ ...secData });
    setProgressHelper(3);
  };

  const handleAuditCompanyChange = 
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      secData.auditCompanyIDs[index] = Number(event.target.value);
      auditReportLinksObj[index].auditedBy = Number(event.target.value);
      setAuditReportLinksObj([...auditReportLinksObj]);
      setSecData({ ...secData });
    };

  const handleVulnSectionStartChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedLinks = [...auditReportLinksObj];
    updatedLinks[index].vulnSectionStart = Number(e.target.value);
    setAuditReportLinksObj(updatedLinks);
  };

  const handleVulnSectionEndChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedLinks = [...auditReportLinksObj];
    updatedLinks[index].vulnSectionEnd = Number(e.target.value);
    setAuditReportLinksObj(updatedLinks);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormInput
            value={secData.bugBounty}
            placeholder={"https://..."}
            onChange={handleBugBountyChange}
            label="Bug bounty"
          />
          <FormInput
            value={secData.bugBountyAmount}
            placeholder={"15"}
            onChange={handleBugBountyAmountChange}
            label="Bug bounty amount in USD"
          />
          <FormInput
            value={secData.paidWhiteHatBefore}
            placeholder="Paid whitehats when..."
            onChange={handlePaidWhiteHatBefore}
            label="Paid whitehat before"
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={20}
            label="Audit Report Links"
            addButtonText="Audit Report"
            handleRemove={handleRemoveAuditLink}
            handleAdd={handleAddAuditLink}
            list={auditReportLinksObj}
            items={[
              {
                type: "input",
                property: "auditReportLink",
                onChange: handleAddAuditLinkChange,
                label: "",
                placeholder: "https://",
                borderIsValid: [],
              },
              {
                type: "select",
                property: "auditedBy",
                onChange: handleAuditCompanyChange,
                label: "",
                display: "name",
                // value: auditReportLinksObj?.[0]?.auditedBy?.toString() || "",
                getValue: (i: number) => {
                  return auditReportLinksObj[i]?.auditedBy?.toString() || "";
                },
                curValue: "id",
                options: allAuditCompanies.map((company) => ({
                  id: company.id,
                  name: company.name,
                })),
              },
              {
                type: "input",
                property: "vulnSectionStart",
                onChange: handleVulnSectionStartChange,
                label: "",
                placeholder: "Vulnerability Section Start (page/section)",
                borderIsValid: [],
              },
              {
                type: "input",
                property: "vulnSectionEnd",
                onChange: handleVulnSectionEndChange,
                label: "",
                placeholder: "Vulnerability Section End (page/section)",
                borderIsValid: [],
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <PurpleButton
          margin="0 auto"
          text="Update Audit Data"
          onClick={sendWritePastAuditDataRequest}
          disabled={protocolData === null || requestSend}
        />
      </div>
    </>
  );
};
