import React from 'react';

interface CountryFlagProps {
  countryCode: string; // The ISO 3166-1 alpha-2 country code (e.g., "us", "fr", "de").
  size?: number; // Size of the flag in pixels.
}

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, size = 20 }) => {
  const flagUrl = `https://flagcdn.com/w${size}/${countryCode.toLowerCase()}.png`;

  return <img src={flagUrl} alt={`${countryCode} flag`} style={{ width: size, height: "auto",border:"solid 1px var(--backgound-color-dark)",borderRadius:"2px" }} />;
};

export default CountryFlag;
