const baseURL = `https://api.scauditstudio-api.de`;

export const productURL = `$${baseURL}/products`;
export const getReportDataURL = `${baseURL}/get-report`;
export const getUnderReviewReportDataURL = `${baseURL}/get-report-under-review`;
export const getReportPreviewsURL = `${baseURL}/get-report-thumbs`;
export const getReportPreviewsFilteredURL = `${baseURL}/get-report-thumbs-filtered`;
export const getReportPreviewsPublishedByUserURL = `${baseURL}/get-report-thumbs-from-user`;
export const getReportPreviewsUnderReviewURL = `${baseURL}/get-report-thumbs-underReview`;
export const getAllAuditCompaniesURL = `${baseURL}/get-all-companies`;
export const writeAuditCompanieURL = `${baseURL}/add-audit-company`;
export const getCompanyByIDURL = `${baseURL}/get-company`;
export const getCompanyByIdArrayURL = `${baseURL}/get-companies`;
export const getUserDataURL = `${baseURL}/userdata`;

export const getReportPreviewsUnderReviewAdURL = `${baseURL}/get-all-report-thumbs-underReview`;
export const getUnderReviewReportDataURLAdURL = `${baseURL}/get-any-report-under-review`;

export const publishReportURL = `${baseURL}/publish`;

export const writeBlockchainURL = `${baseURL}/addBlockchain`;
export const getAllBlockchainsURL = `${baseURL}/getAllBlockchains`;

export const loginAdminURL = `${baseURL}/adminLogin`;
export const writeBasicDataURL = `${baseURL}/add-basic-data`;
export const writeSecurityScoreURL = `${baseURL}/add-security-score`;
export const writeDeCentralizationScoreURL = `${baseURL}/add-decentralization-score`;
export const updateSecurityInfraDataURL = `${baseURL}/add-secInfra-data`;
export const writePastAuditDataURL = `${baseURL}/add-pastAudit-data`;
export const writeCentralizationDataURL = `${baseURL}/addCentralization`;
export const writeCodeDataURL = `${baseURL}/addCodeData`;

export const verifyCodeURL = `${baseURL}/validate`;
export const loginURL = `${baseURL}/login`;
export const registerUserURL = `${baseURL}/register`;

export const getRequestCustomReportURL = `https://forms.gle/mH9pF82faBKhmCUy6`;
export const reportFeedbackURL = `https://forms.gle/dtvPsckAiwUx622S7`;

export const centralizationDocsURL = `https://scas.gitbook.io/scas-metrics/centralization-score`;
export const ratingDocsURL = `https://scas.gitbook.io/scas-metrics`;
export const otherDocsURL = `https://scas.gitbook.io/scas-metrics/other-scores`;
