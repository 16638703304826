import React, { useState } from "react";
import { ethers, Contract } from "ethers";
import { toast } from "react-toastify";
import { useTheme } from "../../hooks/useTheme";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import RegisterForm from "./RegisterFormStep";
import WalletConnectStep from "./WalletConnectStep";
import PaymentStep from "./PaymentStep";
import ConfirmationCodeStep from "./ConfirmationCodeStep";

import { registerUserURL } from "../../api";
import { sendRequest } from "../../utils/apiRequest";

import styles from "./OnboardingWorkflow.module.css";
import CircularProgress from "../Reusable/CircularProgress";
import Stepper from "./Stepper";

// TODO: This contract address should not be hardcoded here.
// Instead, it should be loaded from an environment variable or configuration file
// e.g. process.env.REACT_APP_USDC_CONTRACT_ADDRESS
// This allows for different addresses in different environments (testnet vs mainnet)
const USDC_CONTRACT_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
const RECIPIENT_ADDRESS = "";

interface IOnboardingWorkflow {
  setIsRegisterPopUpVisible: (bool: boolean) => void;
  isProSignUp: boolean;
  user: Object | null;
  setUser: (user: any) => void;
  onboardingWorkflowStep? : OnboardingWorkflowStep;
}

interface IFormData {
  email: string;
  password: string;
}

export enum OnboardingWorkflowStep {
  RegisterForm = "RegisterForm",
  WalletConnect = "WalletConnect",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

const OnboardingWorkflow: React.FC<IOnboardingWorkflow> = ({
  setIsRegisterPopUpVisible,
  isProSignUp,
  onboardingWorkflowStep,
}) => {
  const isDarkTheme = useTheme();
  const [email, setEmail] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [registerPending, setRegisterPending] = useState<boolean>(false);
  const navigate = useNavigate();
  
  const [step, setStep] = useState<OnboardingWorkflowStep>(
    onboardingWorkflowStep !== undefined ?
    onboardingWorkflowStep :
    OnboardingWorkflowStep.RegisterForm,
  );

  const handleRegisterSubmit = async (formData: IFormData) => {
    setEmail(formData.email);
    setPassword(formData.password);
    setRegisterPending(true);
    setRegisterPending(false);
    setStep(OnboardingWorkflowStep.WalletConnect);
  };

  const handleWalletStepCompleted = async () => {
    setRegisterPending(true);
    const request = { email: email, pw: password, wallet: wallet };
    try {
      const response = await sendRequest(
        registerUserURL,
        "Error during wallet connection",
        undefined,
        request,
      );
      if (!response) {
        toast.error(response);
        return;
      }
      setStep(OnboardingWorkflowStep.Confirmation);
    } catch (error: any) {
      if (error.response && error.response.data) {
        alert("error.response.data");
        toast.error(error.response.data);
      } else {
        toast.error("An error occurred during registration");
      }
    } finally {
      setRegisterPending(false);
    }
  };

  const handleConfirmationCompleted = async () => {
    if (isProSignUp) {
      setStep(OnboardingWorkflowStep.Payment);
      await onBuyHandler("499", 1);
      return;
    }
    setIsRegisterPopUpVisible(false);
  };

  const handleWalletConnect = async () => {
    let provider, signer;

    /**
     * window.ethereum is a provider object that gets automatically injected into the window
     * by MetaMask or other Web3 wallet browser extensions. This object allows websites
     * to request users' Ethereum accounts, read data from blockchains the user is connected to,
     * and suggest that the user sign messages and transactions.
     *
     * @see https://docs.metamask.io/guide/ethereum-provider.html
     */
    // @ts-ignore
    if (window.ethereum == null) {
      console.log("no metamask found");
    } else {
      try {
        // @ts-ignore
        provider = new ethers.BrowserProvider(window.ethereum);
        console.log("register1");
        signer = await provider.getSigner();
        const message: string = "Login";
        console.log("register2");
        const signature = await signer.signMessage(message);

        const recoveredAddress = ethers.verifyMessage(message, signature);
        if (recoveredAddress === (await signer.getAddress())) {
          setWallet(recoveredAddress);
          toast.success("Sign Up");
        } else {
          toast.error("Something wqent wrong!");
        }
      } catch (error) {
        toast.error("Something went wrong!");
        console.log("error:", error);
      }
    }
  };

  const onBuyHandler = async (price: string, productID: number) => {
    let provider, signer;
    // @ts-ignore
    if (window.ethereum == null) {
      console.log("no metamask found");
    } else {
      try {
        // @ts-ignore
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
        const { chainId } = await provider.getNetwork();
        if (chainId !== BigInt(137)) {
          toast.error("Something went wrong!");
          return;
        }
        const abi = [
          "function transfer(address to, uint amount) external returns(bool)",
        ];

        const contract = new Contract(USDC_CONTRACT_ADDRESS, abi, signer);

        const usdcAmount = price;

        const transaction = await contract.transfer(
          RECIPIENT_ADDRESS,
          usdcAmount,
        );
        // Wait for the transaction to be mined
        const receipt = await transaction.wait();

        // Get the transaction hash
        const txHash = receipt.transactionHash;
        console.log("Transaction successful" + txHash);
        //await sendPaymentStartRequest("test555",String(signer.address),Number(usdcAmount),productID);
        toast.success("Thank you for your purchase!");
      } catch (error) {
        toast.error("Something went wrong!");
        console.log("error:", error);
      }
    }
  };

  const STEPS = [
    { id: OnboardingWorkflowStep.RegisterForm, label: "Sign Up" },
    { id: OnboardingWorkflowStep.WalletConnect, label: "Wallet Connect" },
    { id: OnboardingWorkflowStep.Confirmation, label: "Confirmation" },
    ...(isProSignUp
      ? [{ id: OnboardingWorkflowStep.Payment, label: "Payment" }]
      : []),
  ];

  return (
    <div className={styles.onboardingWorkflow}>
      <div
        className={`${styles.onboardingWorkflow__inner} ${isDarkTheme ? styles["onboardingWorkflow__inner--dark"] : ""}`}
      >
        <div className={styles.onboardingWorkflow__gradient}>
          <div onClick={() => navigate(-1)} className={styles.onboardingWorkflow__backArrow}>
            <IoArrowBackSharp size={16} />
          </div>
          <img
            src={
              isDarkTheme
                ? "/scaslogo-transparent-dark.png"
                : "/scaslogo-transparent-light.png"
            }
            alt="SCAS Logo"
            className={styles.onboardingWorkflow__logo}
          />
        </div>

        <div className={styles.onboardingWorkflow__content}>
          <div className={styles.onboardingWorkflow__steps}>
            <Stepper steps={STEPS} currentStep={step} />
            <div className={styles.onboardingWorkflow__separator}></div>
            <div className={styles["onboardingWorkflow__step-indicator"]}>
              Step {STEPS.findIndex((s) => s.id === step) + 1}/{STEPS.length}
            </div>

            {registerPending && <CircularProgress />}

            {!registerPending && (
              <>
                {step === OnboardingWorkflowStep.RegisterForm && (
                  <RegisterForm
                    buttonText="Next Step"
                    handleRegisterSubmit={handleRegisterSubmit}
                  />
                )}

                {step === OnboardingWorkflowStep.WalletConnect && (
                  <WalletConnectStep
                    wallet={wallet}
                    handleWalletConnect={handleWalletConnect}
                    handleWalletStepCompleted={handleWalletStepCompleted}
                  />
                )}

                {step === OnboardingWorkflowStep.Confirmation && (
                  <ConfirmationCodeStep
                    email={email}
                    isProSignUp={isProSignUp}
                    handleConfirmationCompleted={handleConfirmationCompleted}
                  />
                )}

                {step === OnboardingWorkflowStep.Payment && (
                  <PaymentStep
                    handleConfirmationCompleted={handleConfirmationCompleted}
                    wallet={wallet}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWorkflow;
