import styles from "./FormRadioInput.module.css";
import { useTheme } from "../../../hooks/useTheme";

export interface IRadioButton {
  label: string;
  value: string;
}

interface IFormRadioInput {
  value: string;
  buttons: Array<IRadioButton>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormRadioInput: React.FC<IFormRadioInput> = ({
  value,
  buttons,
  onChange,
}) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`${styles["form-radio"]} ${styles[isDarkTheme ? "theme-dark" : "theme-light"]}`}
    >
      {buttons.map((button, index) => (
        <label key={index} className={styles["form-radio__label"]}>
          <input
            type="radio"
            value={button.value}
            checked={value === button.value}
            onChange={onChange}
            className={styles["form-radio__input"]}
          />
          <span className={styles["form-radio__text"]}>{button.label}</span>
        </label>
      ))}
    </div>
  );
};

export default FormRadioInput;
