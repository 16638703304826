import React from "react";
import styles from "./FormInput.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  containerClassName?: string;
  label?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  icon,
  className,
  containerClassName,
  label,
  ...props
}) => {
  const isDarkTheme = useTheme();
  const containerClasses = [
    styles["form-field"],
    isDarkTheme ? styles["theme-dark"] : styles["theme-light"],
    icon && styles["form-field--with-icon"],
    containerClassName,
  ]
    .filter(Boolean)
    .join(" ");

  const inputClasses = [styles["form-field__input"], className]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={containerClasses}>
      {label && <label className={styles["form-field__label"]}>{label}</label>}
      <input className={inputClasses} {...props} />
      {icon && <span className={styles["form-field__icon"]}>{icon}</span>}
    </div>
  );
};

export default FormInput;
