import React from "react";
import styles from "./FormTextArea.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface FormTextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  label?: string;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  className,
  containerClassName,
  label,
  ...props
}) => {
  const isDarkTheme = useTheme();
  const containerClasses = [
    styles["form-field"],
    isDarkTheme ? styles["theme-dark"] : styles["theme-light"],
    containerClassName,
  ]
    .filter(Boolean)
    .join(" ");

  const textareaClasses = [styles["form-field__input"], className]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={containerClasses}>
      {label && <label className={styles["form-field__label"]}>{label}</label>}
      <textarea className={textareaClasses} {...props} />
    </div>
  );
};

export default FormTextArea;
