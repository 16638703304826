import { OnboardingWorkflowStep } from "../../components/OnboardingWorkflow";

// TODO: fix issues in this file

import styles from "./PaymentPage.module.css";
import OnboardingWorkflow from "../../components/OnboardingWorkflow";

const PaymentPage = () => {
  return (
    <div className={styles["pre-onboarding__container"]}>
      <OnboardingWorkflow
        setUser={() => {}}
        user={null}
        setIsRegisterPopUpVisible={() =>
          {}
        }
        isProSignUp={true}
        onboardingWorkflowStep={OnboardingWorkflowStep.Payment}
      />
    </div>
  );
};

export default PaymentPage;
