import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { MdGppGood } from "react-icons/md";
import styles from "./AccountPage.module.css";
import ProgressBar from "../../components/Reusable/ProgressBar";
import {
  getUserDataURL,
  loginURL,
  ratingDocsURL,
  verifyCodeURL,
} from "../../api";
import { loginRequest, userData } from "../../utils/severRequestTypes";
import { sendRequest } from "../../utils/apiRequest";
import { formatDate } from "../../utils/textformUtils";
import VerifyEmailForm from "./VerifyEmailForm";
import LoginForm from "./LoginForm";

interface IAccountPage {
  user: userData | null;
  setUser: (user: userData | null) => void;
}

const AccountPage: React.FC<IAccountPage> = ({ user, setUser }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [showVerify, setShowVerify] = useState(false);

  const copyAPIKey = () => {
    if (user?.apiData?.key) {
      navigator.clipboard.writeText(user.apiData.key);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      await sendRequest(
        getUserDataURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setUser,
      );
    };
    if (user !== null) {
      fetchUserData();
    }
  }, []);

  const handleConfirmSubmit = async (email: string, verifyCode: string) => {
    const request = { email: email, code: verifyCode };
    const success = await sendRequest(
      verifyCodeURL,
      "Error Verify!",
      "Code is valid!",
      request,
    );
    setShowVerify(!success);
  };

  const getFormattedDate = (dateString: string | undefined) => {
    if (!dateString) return "Not available";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid date";
    return formatDate(dateString);
  };

  const handleLogin = async (email: string, password: string) => {
    const request: loginRequest = { email, pw: password };
    const response = await sendRequest(
      loginURL,
      "Error on Login",
      undefined,
      request,
      setUser,
    );

    if (!response) return;
    setShowLogin(false);
  };

  if (showVerify) {
    return (
      <VerifyEmailForm
        email={user?.email || ""}
        onConfirm={handleConfirmSubmit}
      />
    );
  }

  if (showLogin && user === null) {
    return (
      <div className={styles.login}>
        <LoginForm onLogin={handleLogin} />
      </div>
    );
  }

  return (
    <div className={styles.account}>
      <div className={styles.account__container}>
        <div className={styles.account__main}>
          <h1 className={styles.account__title}>Welcome!</h1>
          <p className={styles.account__subtitle}>
            View how many API Calls you have left, upgrade to SCAS Pro and more
          </p>

          <div className={styles["info-grid"]}>
            <div className={styles["info-section"]}>
              <h2 className={styles["info-section__title"]}>Email Address</h2>
              <p>
                {user?.email || (
                  <span className={styles["info-section__placeholder"]}>
                    Not specified
                  </span>
                )}
              </p>
            </div>
            <div className={styles["info-section"]}>
              <h2 className={styles["info-section__title"]}>
                Wallet Address
              </h2>
              <p>{user?.wallet}</p>
            </div>

            <div className={styles["info-section"]}>
              <h2 className={styles["info-section__title"]}>
                Monthly API Calls used
              </h2>
              <div className={styles["progress__container"]}>
                <ProgressBar
                  completed={((user?.apiData?.calls ?? 0) / 500) * 100}
                />
                <p>{user?.apiData?.calls} / 500</p>
              </div>
            </div>

            <div className={styles["info-section"]}>
            <h2 className={styles["info-section__title"]}>API Key</h2>
              {user?.apiData?.key ? (
                <div className={styles["api-key"]}>
                  <button
                    onClick={copyAPIKey}
                    className={styles["api-key__copy-button"]}
                    title="Copy API key"
                  >
                    <FaRegCopy /> Copy Key
                  </button>
                </div>
              ) : (
                <p className={styles["info-section__placeholder"]}>
                  No API Key available
                </p>
              )}
            </div>
          </div>

          <div className={styles["info-section"]}>
              <h2 className={styles["info-section__title"]}>
                Next Billing Period
              </h2>
              <p>{getFormattedDate(user?.apiData?.nextPeriod)}</p>
          </div>

          <div className={styles["button-container"]}>
            <Link to="../publish" className={styles["button-link"]}>
              <button
                className={`${styles.button} ${styles["button--primary"]}`}
              >
                Submit Reports
              </button>
            </Link>
            <Link to={ratingDocsURL} className={styles["button-link"]}>
              <button
                className={`${styles.button} ${styles["button--primary"]}`}
              >
                View API Docs
              </button>
            </Link>
            <button
              className={`${styles.button} ${styles["button--tertiary"]}`}
              onClick={() => setUser(null)}
            >
              Logout
            </button>
          </div>
        </div>
        <div className={styles.alerts}>
          <Link to='/publish' className={styles.alert} >
            <div className={styles.alert__icon}>
              <MdGppGood size={28} />
            </div>
            <p>
              Get SCAS Pro for free <br />
              <span className={styles.account__alter__subtitle}>
                Submit 5 reports to earn a 3-month subscription to SCAS Pro!
              </span>
            </p>
          </Link>
          <Link to='/join-beta/purchase' className={styles.alert}>
            <div className={styles.alert__icon}>
              <MdGppGood size={28} />
            </div>
            <p>
              Get SCAS Pro <br />
              <span className={styles.account__alter__subtitle}>
                Unlock more API Calls and Pro Features
              </span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
