import { auditCompaniesType } from "./reportDataTypes";

export function getType(type: auditCompaniesType) {
  const newType = type.toString();
  if (newType === "ContestPlatform") {
    return "Contest Platform";
  } else if (newType === "IndependantResearcher") {
    return "Independent Researcher";
  } else {
    return newType;
  }
}
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

export function containsUpperCase(str: string) {
  return /[A-Z]/.test(str);
}

export function isPasswordValid(inputString: string) {
  return (
    !inputString.includes(" ") &&
    inputString.length > 7 &&
    containsUpperCase(inputString)
  );
}

export function isValidEmail(email: string) {
  var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}
