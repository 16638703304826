import styles from "./Stepper.module.css";

interface Step {
  number: number;
  label: string;
  isActive: boolean;
}

interface StepperProps {
  steps: Step[];
  currentStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => (
        <div key={step.number} className={styles.stepper__item}>
          <div
            className={`${styles.stepper__number} ${
              step.number === currentStep
                ? styles["stepper__number--active"]
                : ""
            }`}
          >
            {step.number}
          </div>
          <span
            className={`${styles.stepper__label} ${
              step.number === currentStep
                ? styles["stepper__label--active"]
                : ""
            }`}
          >
            {step.label}
          </span>
          {index < steps.length - 1 && (
            <div className={styles.stepper__divider} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
