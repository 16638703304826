import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./Navbar.module.css";
import { IoList } from "react-icons/io5";
import inputControlStyles from "../../styles/common/inputControls.module.css";

interface INavbar {
  user: Object | null;
}

const Navbar: React.FC<INavbar> = ({ user }) => {
  const location = useLocation();
  const linkStyling: boolean = location.pathname.split("/")[1] === "protocol";

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar__container}>
        <div className={styles.navbar__logo}>
          <Link
            className={`${styles["navbar__logo-link"]} ${
              linkStyling ? styles["navbar__logo-link--fixed"] : ""
            }`}
            to={`/`}
          >
            SCAuditStudio
          </Link>
        </div>

        <div className={styles.navbar__links}>
          <NavLink
            key={1}
            to="/auditCompanies"
            className={({ isActive }) =>
              `${styles.navbar__link} ${
                isActive ? styles["navbar__link--active"] : ""
              }`
            }
          >
            <IoList className={styles["navbar__link-icon"]} />
            <span> Audit Companies </span>
          </NavLink>

          <NavLink
            key={2}
            to="/all-protocols"
            className={({ isActive }) =>
              `${styles.navbar__link} ${
                isActive ? styles["navbar__link--active"] : ""
              }`
            }
          >
            <IoList className={styles["navbar__link-icon"]} />
            <span>Protocols</span>
          </NavLink>

          <NavLink
            to="/account"
            className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          >
            {user ? "Account" : "Log in"}
          </NavLink>
          {!user ? (
            <NavLink
              to="/join-beta"
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            >
              Register
            </NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
