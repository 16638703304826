import styles from "./PreOnboardingWorkflowPage.module.css";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import OnboardingWorkflow from "../../components/OnboardingWorkflow";
import { Link } from "react-router-dom";

import { useTheme } from "../../hooks/useTheme";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import { IoArrowBackSharp } from "react-icons/io5";

interface IPreRegisterPage {
  user: Object | null;
  setUser: (user: any) => void;
}

const PreOnboardingWorkflowPage: React.FC<IPreRegisterPage> = ({
  user,
  setUser,
}) => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [isProSignUp, setIsProSignUp] = useState(false);
  const isDarkTheme = useTheme();

  const handleRegisterChange = ({ isProSignUp }: { isProSignUp?: boolean }) => {
    setShowSignUp((prev) => !prev);
    if (isProSignUp !== undefined) {
      setIsProSignUp(isProSignUp);
    }
  };

  const features = [
    "Request up to 2 custom reports per month",
    "API access with up to 10.000 requests per month",
    "Create custom search querys",
    "Create custom graphs directly in SCAS",
    "Search 300+ codebases and 1000+ past audit reports",
  ];
  const featuresFree = [
    "Search all data on SCAS",
    "API access with up to 500 requests per month",
  ];

  if (showSignUp) {
    return (
      <div className={styles["pre-onboarding__container"]}>
        <OnboardingWorkflow
          setUser={setUser}
          user={user}
          setIsRegisterPopUpVisible={() =>
            handleRegisterChange({ isProSignUp: false })
          }
          isProSignUp={isProSignUp}
        />
      </div>
    );
  }
  return (
    <div
      className={`${styles["pre-onboarding__container"]} ${isDarkTheme ? "dark-theme" : ""}`}
    >
      <div className={styles["pre-onboarding__inner-container"]}>
      <div className={`${styles["pre-onboarding__get-pro-container"]}`}>
          <h3>Get SCAS</h3>
          <div className={styles["pre-onboarding__description"]}>
            <p>Get SCAS for limited access to codebase, access API and more</p>
          </div>
          <ul className={styles["pre-onboarding__feature-list"]}>
            {featuresFree.map((feature) => {
              return (
                <li
                  key={feature}
                  className={styles["pre-onboarding__feature-list-item"]}
                >
                  <FaCheck /> {feature}
                </li>
              );
            })}
          </ul>
          <div className={styles["pre-onboarding__pricing"]}>
            <p>- Free / Forever</p>
          </div>
          <div className={styles["pre-onboarding__button-container"]}>
            <button
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
              onClick={() => handleRegisterChange({ isProSignUp: false })}
              style={{ fontSize: "14px" }}
            >
              Create Free Account
            </button>
          </div>
          <p className={styles["pre-onboarding__grey-text"]}>
            Already have an account? <Link to="../account">Login</Link>
          </p>
        </div>

        <div className={`${styles["pre-onboarding__get-pro-container"]} ${styles["pre-onboarding__get-pro-container--pro"]}`}>
          <h3>Get SCAS Pro</h3>
          <div className={styles["pre-onboarding__description"]}>
            <p>Get SCAS Pro to access our search, access API and more</p>
          </div>
          <ul className={styles["pre-onboarding__feature-list"]}>
            {features.map((feature) => {
              return (
                <li
                  key={feature}
                  className={styles["pre-onboarding__feature-list-item"]}
                >
                  <FaCheck /> {feature}
                </li>
              );
            })}
          </ul>
          <div className={styles["pre-onboarding__pricing"]}>
            <p>- 499 USDC / user / 6 Month </p>
          </div>
          <div className={styles["pre-onboarding__button-container"]}>
            <button
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
              onClick={() => handleRegisterChange({ isProSignUp: true })}
              style={{ fontSize: "14px" }}
            >
              Get SCAS Pro
            </button>
          </div>
          <p className={styles["pre-onboarding__grey-text"]}>
            Already have an account? <a href="./account">Login</a>
          </p>
        </div>

        <div className={styles["pre-onboarding__get-pro-container"]}>
          <h3>Enterprise Plan</h3>
          <div className={styles["pre-onboarding__description"]}>
            <p>For large organizations with high-volume needs</p>
          </div>
          <ul className={styles["pre-onboarding__feature-list"]}>
            {/* Add enterprise plan features here */}
          </ul>
          <div className={styles["pre-onboarding__pricing"]}>
            <p>- Contact sales for pricing</p>
          </div>
          <div className={styles["pre-onboarding__button-container"]}>
            <button
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
              style={{ fontSize: "14px" }}
            >
              Contact Sales
            </button>
          </div>
          <p className={styles["pre-onboarding__grey-text"]}>
            Already have an account? <Link to="../account">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreOnboardingWorkflowPage;
