import styles from "./SideMenu.module.css";
import { FaFileCirclePlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../hooks/useTheme";

interface ISideMenu {
  isAdmin?:boolean
}

const SideMeunPublishMainPage: React.FC<ISideMenu> = ({
  isAdmin
}) => {
  const navigate = useNavigate();
  const isDarkTheme = useTheme();

  const iconColor = isDarkTheme 
    ? "rgba(255, 255, 255, 0.87)" 
    : "rgba(0, 0, 0, 0.87)";
  return (
    <>
      <div className={`${styles['side-menu']} ${isDarkTheme ? styles['side-menu--dark'] : styles['side-menu--light']}`}>
        <ul className={styles['side-menu__list']}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 20px 10px 20px",
            }}
          >
            <b className={styles['side-menu__header-text']}>
              Create new Protocol 
            </b>
          </div>

          <li
            className={styles['side-menu__button']}
            onClick={() => navigate(`/publish/protocol`)}
          >
            <FaFileCirclePlus size={18} color={iconColor} />
            <span className={styles['side-menu__text']}>Add New Protocol</span>
          </li>
          {isAdmin === true ?
            <li
            className={styles['side-menu__button']}
            onClick={() => navigate(`/publish/auditcompany`)}
          >
            <FaFileCirclePlus size={18} color={iconColor} />
            <span className={styles['side-menu__text']}>Add Audit Company</span>
          </li>
          :""}
          {isAdmin === true ?
            <li
            className={styles['side-menu__button']}
            onClick={() => navigate(`/publish/blockchain`)}
          >
            <FaFileCirclePlus size={18} color={iconColor} />
            <span className={styles['side-menu__text']}>Add New Blockchain</span>
          </li>
          :""}
        </ul>
      </div>
    </>
  );
};

export default SideMeunPublishMainPage;
