import React, { useEffect, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import styles from "./PaymentStep.module.css";
import stepStyles from "../OnboardingStep.module.css";
import commonFormStyles from "../../../styles/common/form.module.css";
import FormInput from "../../Reusable/FormInput";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import axios from "axios";
import CountryFlag from "../../Reusable/FlagLogo";

type geolocation = {
  country_code: string;
  country_name: string;
  city: string;
  postal: number;
  latitude: number;
  longitude: number;
  IPv4: string;
  state: string;
}

const usdcLink =
  "https://polygonscan.com/token/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359";

interface IPaymentStep {
  handleConfirmationCompleted: () => void;
  wallet: string;
}

const PaymentStep: React.FC<IPaymentStep> = ({
  wallet,
  handleConfirmationCompleted,
}) => {
  const isDarkTheme = useTheme();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZipcode] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [geolocation, setGeoLocation] = useState<geolocation>();

  useEffect(() => {
    const fetchData = async () => {
      await getUserLocation();
    };
    fetchData();
  }, []);

  const getUserLocation = async () => {
    try {
      const response = await axios.get('https://geolocation-db.com/json/');
      setGeoLocation(response.data);
      setIpAddress(response.data.IPv4);
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  }

  return (
    <div
      className={`${styles["payment-page"]} ${
        isDarkTheme
          ? styles["payment-page--dark"]
          : styles["payment-page--light"]
      } ${stepStyles["onboarding-step"]}`}
    >
     <div className={commonFormStyles["form-header"]}>
        <h2 className={commonFormStyles["form-header__title"]}>
          Purchase SCAS Pro
        </h2>
        <p className={commonFormStyles["form-header__subtitle"]}>
          Payments are made in Stablecoin. We use FlexyBridge for better UX.
          You can also send payments directly to our wallet.{" "}
        </p>
      </div>

      <div className={commonFormStyles["form-row"]}>
        <FormInput
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
        <FormInput
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
      </div>

      <div className={commonFormStyles["form-row"]}>
      <FormInput
          type="text"
          placeholder="Street Address"
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
        <FormInput
          type="text"
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
      </div>
      <div className={commonFormStyles["form-row"]}>
      <FormInput
          type="text"
          placeholder="State / Province"
          value={state}
          onChange={(e) => setState(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
        <FormInput
          type="text"
          placeholder="Zip Code"
          value={zip}
          onChange={(e) => setZipcode(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
      </div>

      <div className={commonFormStyles["form-checkbox"]}>
      {geolocation !== undefined ? <CountryFlag countryCode={geolocation.country_code}/> : ""}
        <p className={commonFormStyles["form-header__subtitle"]}>
          {geolocation !== undefined ? geolocation.country_name : ""}
        </p>
      </div>

      {formError && (
        <p className={commonFormStyles["form-error"]}>{formError}</p>
      )}
      <div className={styles["payment-page__button-wrapper"]}>
      <button
        className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
        onClick={handleConfirmationCompleted}
      >
        {"Purchase for $499.00"}
      </button>
      </div>
    </div>
  );
};

export default PaymentStep;
