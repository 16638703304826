import styles from "./FormSelect.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface IFormSelect {
  options: Array<{ [key: string]: any }>;
  value: string;
  display: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  curValue: string;
  label?: string;
}

const FormSelect: React.FC<IFormSelect> = ({
  options,
  value,
  display,
  onChange,
  curValue,
  label,
}) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`${styles["form-field"]} ${styles[isDarkTheme ? "theme-dark" : "theme-light"]}`}
    >
      {label && <label className={styles["form-field__label"]}>{label}</label>}
      <select
        className={styles["form-select"]}
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option[curValue]}>
            {option[display]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
