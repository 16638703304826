export function isValidURL(link: string): boolean {
  const urlPattern =
    /^(https?:\/\/)?(www\.)?([A-Za-z0-9-]+\.)+[A-Za-z]{2,8}(:[0-9]+)?(\/\S*)?$/;
  return urlPattern.test(link);
}
export function isNumber(value: number) {
  return typeof value === "number" && isFinite(value);
}

// TODO: These styles should be moved to the appropriate CSS module file (e.g., FormInput.module.css)
// Having UI styles in adminUtils is not ideal for maintainability and separation of concerns
export const validBorder = { border: "1px solid var(--input-border-color)" };
export const inValidBorder = { border: "1px solid rgba(255, 99, 132, 1)" };

export const checkIfValid = (
  array: Array<boolean | Array<boolean>>,
): boolean => {
  return !array.flat().includes(false);
};
export function isValidLogoURL(link: string): boolean {
  const urlPattern =
    /^(https?:\/\/)?(www\.)?([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}(:[0-9]+)?(\/\S*)?\.(png|jpg)$/;
  return urlPattern.test(link);
}
