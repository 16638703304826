import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "reactflow/dist/style.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PublicationsPage from "./pages/PublicationsPage";
import ProtocolPage from "./pages/ProtocolPage";
import PublishPage from "./pages/PublishPage";
import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./utils/theme";
import AuditCompaniesPage from "./pages/AuditCompaniesPage";
import AllProtocolsPage from "./pages/AllProtocolsPage";
import UnsupportedScreen from "./pages/UnsupportedScreen";
import PreOnboardingWorkflowPage from "./pages/PreOnboardingWorkflowPage";
import ForgotPassword from "./pages/ForgotPassword";
import AccountPage from "./pages/AccountPage";
import { userData } from "./utils/severRequestTypes";
import { protocolPreview } from "./utils/reportDataTypes";
import { themeProperties, cssVariables } from "./utils/themeUtils";
import AddProtocolPage from "./pages/PublishPage/AddProtocolPage";
import { AddAuditCompany } from "./pages/PublishPage/AddAuditCompanyPage";
import { AddBlockchain } from "./pages/PublishPage/AddBlockChainPage";
import PaymentPage from "./pages/PaymentPage";

let isPageFirstLoad = true;

const AppContent = () => {
  const location = useLocation();
  const showNavbarAndFooter = !location.pathname.includes("/join-beta");
  const [isDark, setIsDark] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 600);
  const [user, setUser] = useState<userData | null>(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [protocolPreviews, setProtocolPreviews] = useState<
    protocolPreview[] | null
  >(null);
  const [currentProtocolID, setCurrentProtocolID] = useState(0);

  const theme = isDark ? darkTheme : lightTheme;
  useEffect(() => {
    if (isPageFirstLoad) {
      sessionStorage.clear();
    }
    isPageFirstLoad = false;
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => setIsMobileScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "light" && isDark) {
      setIsDark(false);
    } else if (savedTheme === "dark" && !isDark) {
      setIsDark(true);
    } else {
      // Set theme-specific properties
      Object.entries(themeProperties).forEach(([property, value]) => {
        document.documentElement.style.setProperty(
          property,
          theme[value as keyof typeof theme],
        );
      });

      // Set other CSS variables
      Object.entries(cssVariables).forEach(([property, value]) => {
        document.documentElement.style.setProperty(
          property,
          typeof value === "function" ? value(isDark) : value,
        );
      });
    }
  }, [theme, isDark]);

  return (
    <div className="app">
      {showNavbarAndFooter && <Navbar user={user} />}
      <div className="main">
        <Routes>
          <Route
            path="/"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <PublicationsPage
                  setCurrentProtocolID={setCurrentProtocolID}
                  currentTheme={theme}
                  setProtocolPreviews={setProtocolPreviews}
                  allprotocolsParam={protocolPreviews}
                />
              )
            }
          />
          <Route
            path="/join-beta"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <PreOnboardingWorkflowPage user={user} setUser={setUser} />
              )
            }
          />
          <Route
            path="/protocol/:title"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <ProtocolPage
                  currentProtocolID={currentProtocolID}
                  currentTheme={theme}
                  user={user}
                />
              )
            }
          />
          <Route
            path="/publish"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <PublishPage
                  setCurrentProtocolID={setCurrentProtocolID}
                  user={user}
                />
              )
            }
          />
          <Route
              path="/publish/protocol"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <AddProtocolPage user={user} />
                )
              }
            />
            <Route
              path="/publish/auditcompany"
              element={
                isMobileScreen ? <UnsupportedScreen /> : <AddAuditCompany />
              }
            />
            <Route
              path="/publish/blockchain"
              element={
                isMobileScreen ? <UnsupportedScreen /> : <AddBlockchain />
              }
            />
          <Route
            path="/auditCompanies"
            element={
              isMobileScreen ? <UnsupportedScreen /> : <AuditCompaniesPage />
            }
          />
          <Route
            path="/all-protocols"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <AllProtocolsPage setCurrentProtocolID={setCurrentProtocolID} />
              )
            }
          />
          <Route
            path="/account"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <AccountPage user={user} setUser={setUser} />
              )
            }
          />
          <Route
            path="/join-beta/purchase"
            element={
              isMobileScreen ? (
                <UnsupportedScreen />
              ) : (
                <PaymentPage />
              )
            }
          />
          <Route
            path="/forgot"
            element={
              isMobileScreen ? <UnsupportedScreen /> : <ForgotPassword />
            }
          />
        </Routes>
      </div>
      {showNavbarAndFooter && <Footer isDark={isDark} setIsDark={setIsDark} />}
      <ToastContainer />
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

export default App;
