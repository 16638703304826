import styles from "./CodeComplexity.module.css";
import { protocolData } from "../../../utils/reportDataTypes";
import React, { useState } from "react";
import Flow from "./FlowChart";
import { RadarChart } from "./RadarChart";
import { darkTheme } from "../../../utils/theme";
import ShowMoreButton from "../../Reusable/ShowMoreButton";
import MetricContainer from "../../Reusable/MetricContainer";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

interface ICodeComplexity {
  protocolData: protocolData;
  currentTheme?: typeof darkTheme;
}

const CodeComplexity = React.forwardRef<HTMLDivElement, ICodeComplexity>(
  ({ protocolData, currentTheme }, ref) => {
    const [showCodeMetrics, setShowCodeMetrics] = useState(false);
    const [showDependencyGraph, setShowDependencyGraph] = useState(false);

    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Code Structure</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={styles.chartContainer}>
              <RadarChart
                currentTheme={currentTheme}
                comparisonData={protocolData.codeComplexity.similarProtocols}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className={styles.InfoContainer}>
              <MetricContainer title={<>Lines of Code </>} content={<>{protocolData.codeComplexity.linesOfCode}</>}/>
              <MetricContainer title={<>Amount of Contracts </>} content={<>{protocolData.codeComplexity.amountOfContracts}</>}/>
              <MetricContainer title={<>External Integrations </>} content={<>{protocolData.codeComplexity.amountOfExternalIntegrations}</>}/>
              </div>
              <Link target="_blank" to={protocolData.codeBaseLink} className={styles.codeBaseLink}>
              <h3>Code Summery</h3> <FaExternalLinkAlt size={12} />
              </Link>
              <p style={{ textAlign: "start", textJustify: "auto" }}>
                {protocolData.codeComplexity.mostComplexCodeParts.replaceAll("0x0A", "\n")}
              </p>
            </div>
          </div>
          <ShowMoreButton
            onClick={() => setShowDependencyGraph(!showDependencyGraph)}
            text={"Show Dependency Graph"}
            showMore={showDependencyGraph}
          />

          <div
            className={styles.dependecyGraphContainer}
            style={{ maxHeight: showDependencyGraph ? "300px" : "0px" }}
          >
            <h3>Code Structure & Dependency </h3>
            <p>{protocolData.codeComplexity.basicCodeStructure}</p>
            <div className={styles.flowChartContainer}>
              <Flow contracts={protocolData.codeComplexity.contractMetrics} />
            </div>
          </div>

          <ShowMoreButton
            onClick={() => setShowCodeMetrics(!showCodeMetrics)}
            text={"Show Codefile metrics"}
            showMore={showCodeMetrics}
          />
          {protocolData.codeComplexity.contractMetrics.length > 0 && (
            <div
              className={styles.vulReportContainer}
              style={{ maxHeight: showCodeMetrics ? "230px" : "0px" }}
            >
              <h3> Code Metrics </h3>
              <ul className={styles.vulReportList}>
                <li className={styles.stickyRow}>
                  <div className={styles.codeInfoFileName}> Contract Name </div>
                  <div className={styles.codeInfo}> Interfaces </div>
                  <div className={styles.codeInfo}> Lines </div>
                  <div className={styles.codeInfo}> nSLOC </div>
                  <div className={styles.codeInfo}> Complex. Score </div>
                </li>
                {protocolData.codeComplexity.contractMetrics.map((contract, i) => {
                  return (
                    <li
                      key={i}
                      style={
                        i !== protocolData.codeComplexity.contractMetrics.length - 1
                          ? {
                              borderBottom:
                                "1px solid var(--ProtocolPage-border-color)",
                              display: "flex",
                            }
                          : { display: "flex" }
                      }
                    >
                      <div className={styles.codeInfoFileName}>
                        {contract.fileName}
                      </div>
                      <div className={styles.codeInfo}>
                        {contract.interfaces}
                      </div>
                      <div className={styles.codeInfo}>
                        {contract.linesOfCode}
                      </div>
                      <div className={styles.codeInfo}>{contract.nSLOC}</div>
                      <div className={styles.codeInfo}>
                        {contract.complexityScore}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default CodeComplexity;
