import { useEffect, useState, useCallback } from "react";
import styles from "./AddProtocol.module.css";
import { BasicInfo } from "../../../components/PublishPageComponents/BasicInfo";
import CodeComplexity from "../../../components/PublishPageComponents/CodeComplexity";
import Centralization from "../../../components/PublishPageComponents/Centralization";
import { protocolData } from "../../../utils/reportDataTypes";
import { SecInfra } from "../../../components/PublishPageComponents/SecInfra";
import ScoreCalculation from "../../../components/PublishPageComponents/ScoreCalculation";
import SideMenuPublishTool from "../../../components/PublishPageComponents/SideMenu";
import Collapsible from "../../../components/Reusable/Collapsible";
import { sendRequest } from "../../../utils/apiRequest";
import {
  getUnderReviewReportDataURL,
  getUnderReviewReportDataURLAdURL,
} from "../../../api";
import { useSearchParams } from "react-router-dom";
import { userData } from "../../../utils/severRequestTypes";
import Stepper from "./Stepper";

interface IAddProtocolPage {
  user: userData | null;
}

const AddProtocolPage: React.FC<IAddProtocolPage> = ({ user }) => {
  const [progressBasicInfo, setProgressBasicInfo] = useState<number[]>([0, 6]);

  const [progressSecInfra, setProgressSecInfra] = useState<number[]>([0, 4]);

  const [progressCodeComp, setProgressCodeComp] = useState<number[]>([0, 4]);

  const [progressCentraInfo, setProgressCentraInfo] = useState<number[]>([
    0, 5,
  ]);
  const [progressScoreCalc, setProgressScoreCalc] = useState<number[]>([0, 2]);

  const [queryParameters] = useSearchParams();
  const currentProtocolID: number | null =
    queryParameters.get("id") !== null
      ? Number.parseInt(queryParameters.get("id") ?? "")
      : null;

  const [currentProtocolData, setCurrentProtocolData] =
    useState<protocolData | null>(null);

  const getCurrentProtocolData = useCallback(
    async (currentProtocolID: number) => {
      await sendRequest(
        user?.isAdmin
          ? getUnderReviewReportDataURLAdURL
          : getUnderReviewReportDataURL,
        "Something went wrong while getting past reports!",
        undefined,
        currentProtocolID,
        setCurrentProtocolData,
      );
    },
    [user?.isAdmin],
  );

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      if (currentProtocolID !== null) {
        await getCurrentProtocolData(currentProtocolID);
      }
    };
    fetchProtocolPreviews();
  }, [currentProtocolID, getCurrentProtocolData]);

  const steps = [
    { number: 1, label: "Edit Report", isActive: true },
    { number: 2, label: "Report is Reviewed", isActive: false },
    { number: 3, label: "Published", isActive: false },
  ];

  return (
    <>
      <div className={styles.protocol} id="adminContainer">
        <SideMenuPublishTool
          totalProgressMax={
            progressBasicInfo[1] +
            progressSecInfra[1] +
            progressCodeComp[1] +
            progressCentraInfo[1]
          }
          totalProgressCurrent={
            progressBasicInfo[0] +
            progressSecInfra[0] +
            progressCodeComp[0] +
            progressCentraInfo[0]
          }
          protocolPreview={currentProtocolData}
        />
        <div className={styles.protocol__content}>
          <div className={styles.protocol__header}>
            <Stepper steps={steps} currentStep={1} />
          </div>
          <div className={styles.protocol__main}>
            <Collapsible
              title={"Add Basic Information"}
              progress={progressBasicInfo}
            >
              <BasicInfo
                protocolData={currentProtocolData}
                setCurrentProtocol={setCurrentProtocolData}
                setProgress={setProgressBasicInfo}
              />
            </Collapsible>
            <Collapsible
              title={"Add Security Infrastructure Information"}
              progress={progressSecInfra}
            >
              <SecInfra
                protocolData={currentProtocolData}
                setProgress={setProgressSecInfra}
              />
            </Collapsible>
            <Collapsible
              title={"Add Code Base Information"}
              progress={progressCodeComp}
            >
              <CodeComplexity
                protocolData={currentProtocolData}
                setProgress={setProgressCodeComp}
              />
            </Collapsible>
            <Collapsible
              title={"Add Centralization Information"}
              progress={progressCentraInfo}
            >
              <Centralization
                protocolData={currentProtocolData}
                setProgress={setProgressCentraInfo}
              />
            </Collapsible>
            <Collapsible title={"Calculate Score"} progress={progressScoreCalc}>
              <ScoreCalculation
                protocolReportID={
                  currentProtocolData !== null ? currentProtocolData.rid : 0
                }
                setProgress={setProgressScoreCalc}
              />
            </Collapsible>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProtocolPage;
