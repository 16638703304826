import styles from "./Centralization.module.css";
import { useEffect, useState } from "react";
import BasicInput from "../../Reusable/BasicInput";
import { centralizationDataSubmittRequest } from "../../../utils/severRequestTypes";
import BasicSelect from "../../Reusable/BasicSelect";
import { writeCentralizationDataURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";
import { protocolData } from "../../../utils/reportDataTypes";
import FormInput from "../../Reusable/FormInput";
import FormSelect from "../../Reusable/FormSelect";

interface ICentralization {
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

const Centralization: React.FC<ICentralization> = ({
  protocolData,
  setProgress,
}) => {
  const [timeLockInformation, setTimeLockInformation] = useState<string>(
    protocolData
      ? protocolData.centralization.timelockInformation !== undefined
        ? protocolData.centralization.timelockInformation
        : ""
      : "",
  );
  const [adminWallet, setAdminWallet] = useState<string>();
  const [adminInformation, setAdminInformation] = useState<string>();
  const [pauseInformation, setPauseInformation] = useState<string>();
  const [frontendInformation, setFrontendInformation] = useState<string>();
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0, 0]);
  const [ownFrontendInformation, setOwnFrontendInformation] =
    useState<string>();
  const [offChainMaintanceScore, setOffChainMaintanceScore] =
    useState<number>();

  useEffect(() => {
    setOffChainMaintanceScore(
      protocolData ? protocolData.centralization.ownFrontendComplexityScore : 1,
    );
    setOwnFrontendInformation(
      protocolData ? protocolData.centralization.possibilityOfOwnFrontend : "",
    );
    setFrontendInformation(
      protocolData ? protocolData.centralization.frontendDeployment : "",
    );
    setPauseInformation(
      protocolData ? protocolData.centralization.pausableInformation : "",
    );
    setAdminInformation(
      protocolData ? protocolData.centralization.adminInformation : "",
    );
    setAdminWallet(protocolData ? protocolData.centralization.adminWallet : "");
    setTimeLockInformation(
      protocolData ? protocolData.centralization.timelockInformation : "",
    );
  }, [protocolData]);

  const sendPublishReportDataRequest = async () => {
    const request: centralizationDataSubmittRequest = {
      reportID: protocolData !== null ? protocolData.rid : 0,
      adminWallet: adminWallet ?? "",
      adminInformation: adminInformation ?? "",
      pausableInformation: pauseInformation ?? "",
      timelockInformation: timeLockInformation ?? "",
      frontendDeployment: frontendInformation ?? "",
      possibilityOfOwnFrontend: ownFrontendInformation ?? "",
      ownFrontendComplexityScore: offChainMaintanceScore ?? 0,
    };

    sendRequest(
      writeCentralizationDataURL,
      "Something went wrong while uploading data!",
      "Centralization added!",
      request,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 5]);
    }
  };
  if (protocolData && protocolData.centralization !== undefined) {
    if (protocolData.centralization.adminWallet.length > 0) {
      setProgressHelper(1);
    }
    if (protocolData.centralization.adminInformation.length > 0) {
      setProgressHelper(2);
    }
    if (protocolData.centralization.pausableInformation.length > 0) {
      setProgressHelper(0);
    }
    if (protocolData.centralization.frontendDeployment.length > 0) {
      setProgressHelper(3);
    }
    if (protocolData.centralization.possibilityOfOwnFrontend.length > 0) {
      setProgressHelper(4);
    }
  }
  const handleTimeLockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeLockInformation(e.target.value);
  };
  const handelAdminWalletChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminWallet(e.target.value);
    setProgressHelper(1);
  };
  const handelAdminInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminInformation(e.target.value);
    setProgressHelper(2);
  };
  const handelPauseInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPauseInformation(e.target.value);
    setProgressHelper(0);
  };
  const handelFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFrontendInformation(e.target.value);
    setProgressHelper(3);
  };
  const handelOwnFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOwnFrontendInformation(e.target.value);
    setProgressHelper(4);
  };

  const handelOffChainScoreChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = Number(e.target.value);
    setOffChainMaintanceScore(value);
  };

  const selectValues = [
    { name: "Excellent (1)", id: 1 },
    { name: "Average (2)", id: 2 },
    { name: "Poor (3)", id: 3 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormInput
            value={timeLockInformation}
            placeholder="Timelock on Stake..."
            onChange={handleTimeLockChange}
            label="Information about Timelocks"
          />
          <FormInput
            value={adminWallet}
            placeholder="0x..."
            onChange={handelAdminWalletChange}
            label="Admin wallet or multisig address"
          />
          <FormInput
            value={adminInformation}
            placeholder="Admin can ..."
            onChange={handelAdminInfoChange}
            label="Information about Admin"
          />
          <FormInput
            value={pauseInformation}
            placeholder="Can be paused.."
            onChange={handelPauseInfoChange}
            label="Information about Pause"
          />
        </div>
        <div className={styles.containerInner}>
          <FormInput
            value={frontendInformation}
            placeholder="https://..."
            onChange={handelFrontendInformationChange}
            label="Frontend Link (App)"
          />
          <FormInput
            value={ownFrontendInformation}
            placeholder="Front end is possible..."
            onChange={handelOwnFrontendInformationChange}
            label="Information about 3rd party Frontend Deployment"
          />
          <FormSelect
            value={offChainMaintanceScore?.toString() || ""}
            onChange={handelOffChainScoreChange}
            label="How easy is it to maintain 3rd party Frontend?"
            options={selectValues}
            display="name"
            curValue="id"
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <PurpleButton
          margin="0 auto"
          text="Upload Centralization Data"
          onClick={sendPublishReportDataRequest}
          disabled={protocolData === null}
        />
      </div>
    </>
  );
};

export default Centralization;
