import React from "react";
import styles from "./Stepper.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface Step {
  id: string;
  label: string;
}

interface StepperProps {
  steps: Step[];
  currentStep: string;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`
      ${styles.stepper}
      ${isDarkTheme ? styles["stepper--dark"] : ""}
    `}
    >
      <div className={styles.stepper__list}>
        {steps.map((step, index) => {
          const isActive = step.id === currentStep;
          const isPast = steps.findIndex((s) => s.id === currentStep) > index;

          return (
            <div key={step.id} className={styles.stepper__item}>
              <div
                className={`
                  ${styles.stepper__indicator}
                  ${isActive ? styles["stepper__indicator--active"] : ""}
                  ${isPast ? styles["stepper__indicator--completed"] : ""}
                `}
              >
                {isPast ? (
                  <div className={styles.stepper__checkmark}>✓</div>
                ) : (
                  <span>{index + 1}</span>
                )}
              </div>
              <div
                className={`
                  ${styles.stepper__label}
                  ${isActive ? styles["stepper__label--active"] : ""}
                `}
              >
                {step.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
