import styles from "./BasicList.module.css";
import { Minus } from "lucide-react";
import { inValidBorder, validBorder } from "../../../utils/adminUtils";
import FormRadioInput from "../FormRadioInput/FormRadioInput";
import FormSelect from "../FormSelect";
import FormInput from "../FormInput";
import FormTextArea from "../FormTextArea";
import PurpleButton from "../PurpleButton";

interface inputItem {
  type: "input";
  property: string;
  onChange: ((i: number) => (e: React.ChangeEvent<HTMLInputElement>) => void) | ((e: React.ChangeEvent<HTMLInputElement>) => void);
  label: string;
  placeholder: string;
  borderIsValid: Array<boolean>;
}

interface textareaItem {
  type: "textarea";
  property: string;
  onChange: (i: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  placeholder: string;
  borderIsValid: Array<boolean>;
}

interface IRadioButton {
  label: string;
  value: string;
}

interface radioItem {
  label: string;
  type: "radio";
  onChange: (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttons: Array<IRadioButton>;
  property: string;
}

interface selectItem {
  label: string;
  type: "select";
  property?: string;
  onChange: (i: number) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<any>;
  value?: string;
  display: string;
  curValue: string;
  getValue?: (i: number) => string;
}

interface IBasicList {
  label?: string;
  handleRemove: (i: number) => void;
  handleAdd: () => void;
  list: Array<any>;
  height?: number;
  items: Array<inputItem | textareaItem | radioItem | selectItem>;
  gap?: number;
  addButtonText?: string;
}

const BasicList: React.FC<IBasicList> = ({
  label,
  handleRemove,
  handleAdd,
  list,
  height,
  items,
  gap,
  addButtonText,
}) => {
  return (
    <div className={styles.basicList}>
      <div className={styles.basicList__header}>
        <label className={styles.basicList__label}>{label}</label>
      </div>

      <div
        className={styles.basicList__list}
        style={{ gap: `${gap}px`, height: `${height}px` }}
      >
        {list.map((n, i) => {
          return (
            <div key={i} className={styles.basicList__item}>
              <div className={styles.basicList__inputs}>
                {items.map((item, i2) => {
                  return (
                    <div className={styles.basicList__input} key={i2}>
                      {item.label && <div>{item.label}</div>}
                      {item.type === "textarea" && (
                        <FormTextArea
                          value={n[item.property]}
                          onChange={item.onChange(i)}
                          placeholder={item.placeholder}
                          style={
                            item.borderIsValid.length === 0 ||
                            item.borderIsValid[i]
                              ? validBorder
                              : inValidBorder
                          }
                        />
                      )}
                      {item.type === "input" && (
                        <FormInput
                          value={n[item.property]}
                          onChange={typeof item.onChange === "function" 
                            ? (item.onChange as ((i: number) => (e: React.ChangeEvent<HTMLInputElement>) => void))(i)
                            : (item.onChange as (e: React.ChangeEvent<HTMLInputElement>) => void)}
                          placeholder={item.placeholder}
                          style={
                            item.borderIsValid.length === 0 ||
                            item.borderIsValid[i]
                              ? validBorder
                              : inValidBorder
                          }
                          containerClassName={styles.basicList__input}
                        />
                      )}
                      {item.type === "radio" && (
                        <FormRadioInput
                          value={n[item.property]}
                          buttons={item.buttons}
                          onChange={item.onChange(i)}
                        />
                      )}
                      {item.type === "select" && (
                        <FormSelect
                          display={item.display}
                          options={item.options}
                          value={item.getValue ? item.getValue(i) : item.value ? item.value : ""}
                          onChange={item.onChange(i)}
                          curValue={item.curValue}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <span
                className={styles.basicList__removeIconWrapper}
                onClick={() => handleRemove(i)}
              >
                <Minus
                  className={styles.basicList__removeIcon}
                  width={16}
                  height={16}
                />
              </span>
            </div>
          );
        })}
      </div>
      <PurpleButton
        text={`+ Add ${addButtonText}`}
        onClick={handleAdd}
        fontSize="14px"
        fixedsize={true}
        width="auto"
      />
    </div>
  );
};

export default BasicList;
